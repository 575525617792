import React, { useState } from 'react';
import './App.css';
import { IconBell } from "@tabler/icons-react";

function App() {
    const [isSuccessVisible, setSuccessVisible] = useState(false);

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const highlightEmailInput = () => {
        const emailInput = document.getElementById('enter-email');
        if (emailInput) {
            emailInput.classList.add('highlight');
            setTimeout(() => {
                emailInput.classList.remove('highlight');
            }, 2000);
        }
    };

    const handleNotifyMeClick = () => {
        const emailInput = document.getElementById('enter-email') as HTMLInputElement;
        if (emailInput) {
            const email = emailInput.value;
            if (!validateEmail(email)) {
                emailInput.classList.add('error');
            } else {
                emailInput.classList.remove('error');
                setSuccessVisible(true);
                setTimeout(() => {
                    document.getElementById('notification-success')?.classList.add('hidden');
                    setTimeout(() => {
                        setSuccessVisible(false);
                        document.getElementById('notification-success')?.classList.remove('hidden');
                    }, 500);
                }, 3000);
            }
        }
    };

    return (
        <>
            <div className='navbar'>
                <img src="/tasklee-full-transparent-green.png" alt=""/>
                <button id='notif' onClick={highlightEmailInput}>
                    <IconBell size={32} id='bell'/>
                </button>
            </div>

            <div className='main-container'>
                <div className='text-container'>
                    <div className='bigtext'>Task management, all in one.</div>
                    <div className='smallertext'>Coming sooner than you think.</div>
                    <div className='email-wrap'>
                        <input type="text" id='enter-email' placeholder='Enter email...'/>
                        <button className='notify-me' onClick={handleNotifyMeClick}>Notify me</button>
                    </div>
                </div>
            </div>

            <div className='footer'>
                <div className='footer-text'>Make sure to read our <a href="https://tasklee.com/terms-of-service"> Terms of Service</a> and <a href="https://tasklee.com/privacy-policy">Privacy Policy</a>.</div>
            </div>

            <div id='notification-success' className={isSuccessVisible ? 'visible' : ''}>
                Signed up!
            </div>

            <img src="/confetti-tasklee.png" alt="c" className='confetti'/>
        </>
    );
}

export default App;